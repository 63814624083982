<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="pb-0" style="justify-content: space-between">
          Графики
        </v-card-title>
        <v-row>
          <YearMonth v-model="month" @change="reloadTable"></YearMonth>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
                multiple
                outlined
                dense
                :items="[...$store.state.toSelectItems($store.state.user.allCities, 'city', 'id')]"
                v-model="cities"
                @change="saveLocal($event);reloadTable()"
                no-data-text="Данных нет"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="cities = [];month = [$moment().format('YYYY'), $moment().format('MM')]">Очистить</v-btn>
          </v-col>
        </v-row>
        <Chart :series="chartSeries"/>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import Chart from "../../components/Chart";
import YearMonth from "../../components/YearMonth";

export default {
  name: "Salary",
  components: {YearMonth, Chart},
  data() {
    let cities = this.$root.getData('regions_charts', 'cities') || ['1'];
    return {
      month: [this.$moment().format('YYYY'), this.$moment().format('MM')],
      chartSeries: [],
      total: {},
      cities,
      citiesPrev: cities,
      loadingTable: true,
    }
  },
  methods: {
    reloadTable() {
      this.chartSeries = [];
      this.loadingTable = true;
      this.$store.state.server.request('regions/chartByMonth', {month: this.month, cities: this.cities}, (data) => {
        this.chartSeries = data.response;
        this.loadingTable = false;
      }, () => {
        this.loadingTable = false
      })
    },
    saveLocal() {
      this.$root.saveData('regions_charts', 'cities', this.cities)
      this.citiesPrev = this.cities.slice();
    }
  },
  mounted() {
    this.reloadTable()
  }
}
</script>
